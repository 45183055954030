import React from 'react'
import styled from 'styled-components'

const StyledContentWrapper = styled.div`
    max-width: ${props => props.theme.pageWidth};
    margin: 0 auto;
    padding: ${props => props.theme.spacingLarge} ${props => props.theme.spacingXBase};

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding: 4rem ${props => props.theme.spacingXBase};
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
        padding: 3rem ${props => props.theme.spacingXsm};
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
        padding: 1.5rem ${props => props.theme.spacingXxs};
    }
`

export default function ContentWrapper(props) {
    return (
        <StyledContentWrapper className={props.className}>
            {props.children}
        </StyledContentWrapper>
    )
}
