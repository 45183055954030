import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59.999"
      height="59.999"
      viewBox="0 0 59.999 59.999"
    >
      <path
        fill="#69b650"
        d="M29.999 59.999a29.911 29.911 0 1111.678-2.357 29.81 29.81 0 01-11.678 2.357zm-9-40a1 1 0 00-1 1v2a17 17 0 0017 17h2a1 1 0 001-1v-.649l-.01.009v-3.35a1 1 0 00-.99-1h-4.02a2.713 2.713 0 00-1.7.709l-2.12 2.121a15.048 15.048 0 01-8-8l2.139-2.141a2.707 2.707 0 00.7-1.7v-4a1 1 0 00-1-1z"
      ></path>
    </svg>
  );
}

export default Icon;