import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59.999"
      height="59.999"
      viewBox="0 0 59.999 59.999"
    >
      <path
        fill="#69b650"
        d="M29.999 59.999a29.911 29.911 0 1111.678-2.357 29.81 29.81 0 01-11.678 2.357zm-8-28v8l20-10-20-10v8l10 2-10 2z"
      ></path>
    </svg>
  );
}

export default Icon;